import { __decorate } from "tslib";
import { Component, Emit, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import RecordField from './RecordField.vue';
import { REMOVE_MERGED_RECORD } from '@/store/comparison-merge/mutations';
import { MERGED_OBJECTS } from '@/store/comparison-merge/getters';
import Expandable from '@/components/shared/Expandable.vue';
let RecordItem = class RecordItem extends Vue {
    constructor() {
        super(...arguments);
        this.recordMergeResult = () => this.mergeResult;
        this.checked = false;
        this.showOnlyDiffs = true;
    }
    get isRecordChecked() {
        return !!this.mergeResult;
    }
    get mergeResult() {
        const records = this.$store.getters[MERGED_OBJECTS] || {};
        const objectRecords = records[this.objectName] || {};
        return objectRecords[this.record.sourceId];
    }
    onCheckedChanged(value) {
        if (!value) {
            this.$store.commit(REMOVE_MERGED_RECORD, {
                objectName: this.objectName,
                recordId: this.record.sourceId,
            });
            if (this.opened) {
                this.onClick();
            }
        }
    }
    onClick() { }
    created() {
        this.fields = Object.freeze(this.describe.fields);
        const describeFields = Object.keys(this.fields);
        const backupFields = this.record.fields.map(field => field.name);
        this.objectFields = Object.freeze([...new Set([...describeFields, ...backupFields])].filter(field => field !== 'Id'));
        this.objectFieldValues = this.record.fields.reduce((acc, next) => ({
            ...acc,
            [next.name]: {
                isDiff: next.isDiff,
                source: next.source,
                target: next.target,
            },
        }), {});
        this.checked = this.isRecordChecked;
    }
};
__decorate([
    Provide()
], RecordItem.prototype, "recordMergeResult", void 0);
__decorate([
    Inject()
], RecordItem.prototype, "objectName", void 0);
__decorate([
    Prop()
], RecordItem.prototype, "describe", void 0);
__decorate([
    Prop()
], RecordItem.prototype, "opened", void 0);
__decorate([
    Prop()
], RecordItem.prototype, "record", void 0);
__decorate([
    Watch('checked')
], RecordItem.prototype, "onCheckedChanged", null);
__decorate([
    Emit()
], RecordItem.prototype, "onClick", null);
RecordItem = __decorate([
    Component({
        components: {
            Expandable,
            RecordField,
        },
    })
], RecordItem);
export default RecordItem;
