import { __decorate } from "tslib";
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
let Expandable = class Expandable extends Vue {
    onClick() { }
};
__decorate([
    Prop()
], Expandable.prototype, "title", void 0);
__decorate([
    Prop()
], Expandable.prototype, "opened", void 0);
__decorate([
    Prop({
        default: false,
    })
], Expandable.prototype, "light", void 0);
__decorate([
    Prop({
        default: true,
    })
], Expandable.prototype, "rounded", void 0);
__decorate([
    Emit()
], Expandable.prototype, "onClick", null);
Expandable = __decorate([
    Component({})
], Expandable);
export default Expandable;
