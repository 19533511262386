var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backups-list"},[_c('f-loading',{attrs:{"shown":_vm.loading}}),_c('f-pagination',{model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}}),_c('f-search-field',{model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('div',{ref:"table",staticClass:"backups-list__table"},[_c('f-table',{staticClass:"mb-0",attrs:{"fields":_vm.tableFields,"items":_vm.backups,"hover":""},on:{"row-clicked":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(index)",fn:function(item){return [_vm._v(" "+_vm._s(item.index + 1 + _vm.localOffset)+" ")]}},{key:"cell(createdDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(+item.createdDate))+" ")]}},{key:"cell(storages)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"word-break":"normal"}},[_vm._v(" "+_vm._s((item.storages || []).map(function (storage) { return storage.name; }).join(', '))+" ")])]}},{key:"cell(size)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("size")(item.size))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[(!!item.storages.length)?_c('div',{staticClass:"d-flex mr-2"},[_c('div',{staticClass:"table__icon",on:{"click":function($event){$event.stopPropagation();return _vm.onBackupDelete(item)}}},[_c('f-icon',{attrs:{"name":"trash"}})],1)]):_vm._e(),(_vm.showUpload && !!item.storages.length && item.storages.find(function (storage) { return storage.type === 'Local'; }))?_c('div',{staticClass:"d-flex mr-2"},[_c('div',{staticClass:"table__icon",on:{"click":function($event){$event.stopPropagation();return _vm.onBackupUpload(item)}}},[_c('f-icon',{attrs:{"name":"cloud_upload"}})],1)]):_vm._e(),(_vm.showDownload && !!item.storages.length && !item.storages.find(function (storage) { return storage.type === 'Local'; }))?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"table__icon",on:{"click":function($event){$event.stopPropagation();return _vm.onBackupDownload(item)}}},[_c('f-icon',{attrs:{"name":"cloud_download"}})],1)]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }