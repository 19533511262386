import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import BackupsApi from '@/services/api/backups.api';
let BackupsList = class BackupsList extends Vue {
    constructor() {
        super(...arguments);
        this.TABLE_FIELD_MAP = {
            index: { label: '#', thStyle: { width: '50px' } },
            name: { label: 'Name', thStyle: { width: '250px' } },
            description: { label: 'Description' },
            createdDate: { label: 'Created Date', thStyle: { width: '300px' } },
            size: { label: 'Size', thStyle: { width: '150px' } },
            status: { label: 'Status', thStyle: { width: '200px' } },
            actions: { label: 'Actions', thStyle: { width: '100px' } },
            storages: { label: 'Storage Type', thStyle: { width: '200px', maxWidth: '200px' } },
        };
        this.pagination = {
            page: 1,
            limit: 25,
            total: 0,
        };
        this.loading = false;
        this.searchText = '';
        this.backups = [];
    }
    get localOffset() {
        return this.pagination.limit * (this.pagination.page - 1);
    }
    get tableFields() {
        const shownColumns = ['index', ...this.shownColumns];
        return shownColumns.map(column => ({
            key: column,
            ...this.TABLE_FIELD_MAP[column],
        }));
    }
    onSearchQueryChanged() {
        this.$nextTick(() => this.fetchBackups());
    }
    onBackupUpload(backup) {
        return backup;
    }
    onBackupDownload(backup) {
        return backup;
    }
    onBackupDelete(backup) {
        return backup;
    }
    onRowSelected(backup) {
        return backup;
    }
    onSearchTextUpdated() {
        this.fetchBackups();
    }
    mounted() {
        this.fetchBackups();
    }
    async fetchBackups() {
        try {
            this.loading = true;
            const result = await BackupsApi.find({
                organizationId: this.organizationId,
                search: this.searchText,
                page: this.pagination.page,
                limit: this.pagination.limit,
                status: this.backupStatuses.join(','),
            });
            this.pagination.total = result.total;
            // this.backups = result.backups;
            this.backups = result.backups.map(backup => ({
                ...backup,
                storageType: 'Local, Azure Dev Ops, AWS S3 Glacier, AWS S3 Some Another',
            }));
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop({
        default: () => [],
    })
], BackupsList.prototype, "backupStatuses", void 0);
__decorate([
    Prop()
], BackupsList.prototype, "organizationId", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], BackupsList.prototype, "shownColumns", void 0);
__decorate([
    Prop({
        default: false,
    })
], BackupsList.prototype, "showUpload", void 0);
__decorate([
    Prop({
        default: false,
    })
], BackupsList.prototype, "showDownload", void 0);
__decorate([
    Watch('pagination.page', { deep: true }),
    Watch('searchText')
], BackupsList.prototype, "onSearchQueryChanged", null);
__decorate([
    Emit()
], BackupsList.prototype, "onBackupUpload", null);
__decorate([
    Emit()
], BackupsList.prototype, "onBackupDownload", null);
__decorate([
    Emit()
], BackupsList.prototype, "onBackupDelete", null);
__decorate([
    Emit()
], BackupsList.prototype, "onRowSelected", null);
BackupsList = __decorate([
    Component({})
], BackupsList);
export default BackupsList;
