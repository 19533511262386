import { __decorate } from "tslib";
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { MERGED_OBJECTS } from '@/store/comparison-merge/getters';
import ComparisonApi from '@/services/api/comparison.api';
import ObjectsPicker from '@/components/backups-comparison/ObjectsPicker.vue';
let DisableAutomation = class DisableAutomation extends Vue {
    constructor() {
        super(...arguments);
        this.selectAll = false;
        this.automationProcess = {
            isDisableTrigger: false,
            isDisableProcessBuilder: false,
            isDisableWorkFlow: false,
            isDisableValidationRule: false,
        };
        this.selectedComparisonOptions = [];
        this.comparisonOptions = [];
        this.loading = true;
        this.loadingText = '';
    }
    mounted() {
        this.uploadMergeResults();
    }
    selectAllObjects() {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            this.selectedComparisonOptions = [...this.comparisonOptions];
        }
        else {
            this.selectedComparisonOptions = [];
        }
    }
    onSelectedObjectsChanged() {
        this.selectAll = this.selectedComparisonOptions.length === this.comparisonOptions.length;
    }
    async onProceedRestore() {
        try {
            this.loading = true;
            const { message } = await ComparisonApi.restoreBackup({
                automationProcess: this.automationProcess,
                projectName: this.comparisonState.projectName,
                orgId: +this.organizationId,
                comparisonOptions: this.comparisonOptions.map(option => ({
                    ...option,
                    isDisableAutomationProcess: this.selectedComparisonOptions.includes(option),
                })),
                storageId: this.comparisonState.storageId,
            });
            this.$toast.success(message);
            await this.$router.push({ name: 'backup-to-org-comparison.index' });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    async uploadMergeResults() {
        const mergeResults = this.$store.getters[MERGED_OBJECTS];
        const types = Object.keys(mergeResults);
        try {
            this.loading = true;
            for (const type of types) {
                this.loadingText = `Prepare ${type} to merge`;
                // eslint-disable-next-line no-await-in-loop
                await ComparisonApi.saveMergeResult({
                    mergeResult: mergeResults[type],
                    objectName: type,
                    projectName: this.comparisonState.projectName,
                    orgId: +this.organizationId,
                    backupId: this.comparisonState.backup?.id,
                    backupOptionId: this.comparisonState.objects?.find(describe => describe.name === type)?.id,
                    storageId: this.comparisonState.storageId,
                });
                this.comparisonOptions.push({
                    isDisableAutomationProcess: false,
                    objectName: type,
                });
            }
            this.loadingText = '';
        }
        catch (error) {
            this.$toast.error(error.message);
            await this.$router.push({ name: 'org.comparison2.index' });
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], DisableAutomation.prototype, "organizationId", void 0);
__decorate([
    Inject()
], DisableAutomation.prototype, "comparisonState", void 0);
__decorate([
    Watch('selectedComparisonOptions')
], DisableAutomation.prototype, "onSelectedObjectsChanged", null);
DisableAutomation = __decorate([
    Component({
        components: {
            ObjectsPicker,
        },
    })
], DisableAutomation);
export default DisableAutomation;
