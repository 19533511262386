import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { StorageType } from '@flosum-backup/contracts';
import VueModal from '@/classes/vue-modal';
let BackupStorageSelectModal = class BackupStorageSelectModal extends VueModal {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.pickedStorage = null;
    }
    get storagesList() {
        return this.storages
            .filter(storage => storage.type !== StorageType.Local)
            .map(storage => ({
            text: storage.name,
            value: {
                id: storage.id,
                type: storage.type,
            },
        }));
    }
    onCancel() {
        this.pickedStorage = null;
        this.close();
    }
    onSelect() {
        try {
            this.validateStorageOptions();
            this.close();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    onModalHidden() {
        this.resolved(this.pickedStorage);
    }
    validateStorageOptions() {
        if (!this.pickedStorage) {
            throw new Error('Pick a storage to continue');
        }
        const pickedStorage = this.storages.find(storage => storage.id === this.pickedStorage.id);
        if (!pickedStorage) {
            throw new Error('Invalid storage');
        }
    }
};
__decorate([
    Prop()
], BackupStorageSelectModal.prototype, "storages", void 0);
__decorate([
    Prop()
], BackupStorageSelectModal.prototype, "name", void 0);
BackupStorageSelectModal = __decorate([
    Component({})
], BackupStorageSelectModal);
export default BackupStorageSelectModal;
