import { __decorate } from "tslib";
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { MergeSource } from '@/interfaces/comparison2';
import { SET_MERGE_RESULT } from '@/store/comparison-merge/mutations';
let RecordField = class RecordField extends Vue {
    constructor() {
        super(...arguments);
        this.direction = {
            source: false,
            target: false,
        };
    }
    get isFieldShown() {
        return this.value && (this.showOnlyDiffs ? this.value.isDiff : true);
    }
    get isFieldRequired() {
        return this.describe?.isRequired;
    }
    onDirectionChanged(value) {
        let mergeSource = MergeSource.Null;
        if (value.source) {
            mergeSource = MergeSource.Backup;
        }
        else if (value.target) {
            mergeSource = MergeSource.Organization;
        }
        this.$store.commit(SET_MERGE_RESULT, {
            fieldName: this.field,
            recordId: this.recordId,
            mergeSource,
            objectName: this.objectName,
        });
    }
    get isActiveCheckbox() {
        return this.active && this.describe?.isCreateable;
    }
    mounted() {
        const mergeSource = this.recordMergeResult && this.recordMergeResult() && this.recordMergeResult()[this.field];
        if (mergeSource) {
            if (mergeSource === MergeSource.Backup) {
                this.direction.source = true;
            }
            else if (mergeSource === MergeSource.Organization) {
                this.direction.target = true;
            }
        }
    }
};
__decorate([
    Inject()
], RecordField.prototype, "objectName", void 0);
__decorate([
    Inject()
], RecordField.prototype, "recordMergeResult", void 0);
__decorate([
    Prop()
], RecordField.prototype, "showOnlyDiffs", void 0);
__decorate([
    Prop()
], RecordField.prototype, "recordId", void 0);
__decorate([
    Prop()
], RecordField.prototype, "active", void 0);
__decorate([
    Prop()
], RecordField.prototype, "field", void 0);
__decorate([
    Prop()
], RecordField.prototype, "describe", void 0);
__decorate([
    Prop()
], RecordField.prototype, "value", void 0);
__decorate([
    Watch('direction', { deep: true })
], RecordField.prototype, "onDirectionChanged", null);
RecordField = __decorate([
    Component({})
], RecordField);
export default RecordField;
