import { __decorate } from "tslib";
import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import Stepper from '@/components/shared/Stepper.vue';
import BackupsList from '@/components/shared/BackupsList.vue';
import BackupObjects from '@/components/comparison/BackupObjects.vue';
import MergeObjects from '@/components/comparison/MergeObjects.vue';
import DisableAutomation from '@/components/comparison/DisableAutomation.vue';
import BackupUtils from '@/utils/backup.utils';
let ComparisonPage = class ComparisonPage extends Vue {
    constructor() {
        super(...arguments);
        this.comparisonState = {
            projectName: this.$route.params.projectName,
            backup: null,
            objects: null,
            storageId: null,
        };
        this.steps = [
            { name: 'backups', title: 'Select backup', active: true, done: false },
            { name: 'objects', title: 'Select objects', active: false, done: false },
            { name: 'merge', title: 'Merge', active: false, done: false },
            { name: 'automation', title: 'Restore settings', active: false, done: false },
        ];
    }
    get activeStep() {
        return this.steps.find(step => step.active);
    }
    nextStep() {
        if (this.activeStep) {
            const activeStepIndex = this.steps.indexOf(this.activeStep);
            this.activeStep.done = true;
            this.activeStep.active = false;
            if (this.steps[activeStepIndex + 1]) {
                this.steps[activeStepIndex + 1].active = true;
            }
        }
    }
    async selectBackup(backup) {
        try {
            const storage = await BackupUtils.getStorageForBackup(backup);
            if (!storage) {
                return;
            }
            this.comparisonState.storageId = storage.id;
            this.comparisonState.backup = backup;
            this.nextStep();
        }
        catch (error) {
            this.$toast.error(error);
        }
    }
    setTypes(objects) {
        this.comparisonState.objects = objects;
        this.nextStep();
    }
    onMerged() {
        this.nextStep();
    }
};
__decorate([
    Inject()
], ComparisonPage.prototype, "organizationId", void 0);
__decorate([
    Provide()
], ComparisonPage.prototype, "comparisonState", void 0);
ComparisonPage = __decorate([
    Component({
        components: {
            BackupObjects,
            Stepper,
            BackupsList,
            MergeObjects,
            DisableAutomation,
        },
    })
], ComparisonPage);
export default ComparisonPage;
