import { __decorate } from "tslib";
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
var ObjectState;
(function (ObjectState) {
    ObjectState[ObjectState["Selected"] = 0] = "Selected";
    ObjectState[ObjectState["Disabled"] = 1] = "Disabled";
    ObjectState[ObjectState["Unselected"] = 2] = "Unselected";
})(ObjectState || (ObjectState = {}));
let ObjectsPicker = class ObjectsPicker extends Vue {
    constructor() {
        super(...arguments);
        this.objectsMap = new Map();
    }
    toggleObject(object) {
        const state = this.objectsMap.get(object);
        const newState = state === ObjectState.Selected ? ObjectState.Unselected : ObjectState.Selected;
        this.objectsMap.set(object, newState);
        this.$forceUpdate();
        this.$emit('change', newState === ObjectState.Selected
            ? [...this.selected, object]
            : this.selected.filter(existedObject => existedObject !== object));
    }
    onPropsUpdated() {
        this.objectsMap = new Map();
        for (const object of this.objects) {
            let state = ObjectState.Unselected;
            if (this.disabled.includes(object)) {
                state = ObjectState.Disabled;
            }
            if (this.selected.includes(object)) {
                state = ObjectState.Selected;
            }
            this.objectsMap.set(object, state);
        }
    }
    onSelectedChanged() {
        for (const object of this.objects) {
            if (this.objectsMap.get(object) !== ObjectState.Disabled) {
                const state = this.selected.includes(object) ? ObjectState.Selected : ObjectState.Unselected;
                this.objectsMap.set(object, state);
            }
        }
        this.$forceUpdate();
    }
};
__decorate([
    Model('change', {
        default: () => [],
    })
], ObjectsPicker.prototype, "selected", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], ObjectsPicker.prototype, "objects", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], ObjectsPicker.prototype, "disabled", void 0);
__decorate([
    Watch('objects', { deep: true, immediate: true })
], ObjectsPicker.prototype, "onPropsUpdated", null);
__decorate([
    Watch('selected', { deep: true })
], ObjectsPicker.prototype, "onSelectedChanged", null);
ObjectsPicker = __decorate([
    Component({
        components: {},
    })
], ObjectsPicker);
export default ObjectsPicker;
