import axios from '../axios-instance';
import { API_URL } from '../api-url/api-url.service';
export default class ComparisonApi {
    static async listBackupObjects(orgId, backupId, projectName) {
        const { data } = await axios.get(`${API_URL}/comparison-backup-to-org/backup-objects`, {
            params: {
                backupId,
                orgId,
                projectName,
            },
        });
        return data;
    }
    static async restoreBackup(body) {
        const { data } = await axios.post(`${API_URL}/comparison-backup-to-org/restore`, body);
        return data;
    }
    static async saveMergeResult(body) {
        const { data } = await axios.post(`${API_URL}/comparison-backup-to-org/merge-records`, body);
        return data;
    }
    static async describeObjects(organizationId, projectName, objects) {
        const { data } = await axios.post(`${API_URL}/comparison-backup-to-org/describe-objects`, {
            organizationId,
            projectName,
            objects,
        });
        return data;
    }
    static async listObjectRecords(body) {
        const { data } = await axios.post(`${API_URL}/comparison-backup-to-org/compare-records`, body);
        return data;
    }
    static async createComparison(orgId) {
        const { data } = await axios.put(`${API_URL}/comparison-backup-to-org/`, undefined, {
            params: {
                orgId,
            },
        });
        return data;
    }
}
