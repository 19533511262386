import Axios from 'axios';
import request from '@/request';
// import Router from '../../router';
export * from 'axios';
const axios = Axios.create({ withCredentials: true });
const LICENSE_FAIL_MESS = 'LICENSE FAIL';
const PASSWORD_RESET_CODE = 'PasswordResetRequiredException';
const NOT_AUTHORIZED_CODE = 'NotAuthorizedException';
const LICENSE_EXPIRED_CODE = 'EXPIRED';
const LICENSE_NOTFOUND_CODE = 'NOT_FOUND';
// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   err => {
//     const redirect = Router.currentRoute.name === 'auth.login' ? '/' : Router.currentRoute.path;
//
//     if (!err.response) {
//       localStorage.clear();
//
//       if (Router.currentRoute.name !== 'auth.login') {
//         Router.push({ name: 'auth.login', query: { redirect } }).then(() => undefined);
//       } else {
//         throw new Error(`Could not receive response from server`);
//       }
//       return;
//     }
//
//     const { status, data } = err.response;
//     const message = data && data.message ? data.message : 'Unhandled server error!';
//
//     if (message === LICENSE_FAIL_MESS) {
//       Router.replace({ name: 'auth.expired' }).catch(() => undefined);
//       throw new Error(`${message}`);
//     }
//
//     if (status === 408 || err.code === 'ECONNABORTED') {
//       throw new Error(`A timeout happend on url ${err.config.url}`);
//     }
//
//     switch (status) {
//       case 400:
//         if (data.code === LICENSE_NOTFOUND_CODE) {
//           throw new Error('Your License key is not valid.');
//         }
//         if (data.code === LICENSE_EXPIRED_CODE) {
//           throw new Error('Your License key has been expired.');
//         }
//         throw new Error(`${message}`);
//       case 401:
//         if (data.error) {
//           throw new Error(data.error);
//         }
//
//         if (data.code === PASSWORD_RESET_CODE) {
//           Router.replace({
//             path: '/change-password',
//             query: { username: data.username, new_password_required: data.isNewPasswordRequired },
//           }).catch(() => undefined);
//           break;
//         }
//
//         if (data.code === NOT_AUTHORIZED_CODE) {
//           throw new Error(`${message}`);
//         }
//
//         Router.replace({
//           name: 'auth.login',
//           query: {
//             redirect,
//           },
//         }).catch(() => undefined);
//         throw new Error(`${message}`);
//       default:
//         throw new Error(`${message}`);
//     }
//   }
// );
export default request;
