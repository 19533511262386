import { __decorate } from "tslib";
import { Component, Inject, Provide, Prop, Vue, Emit } from 'vue-property-decorator';
import RecordItem from './RecordItem.vue';
import ComparisonApi from '../../services/api/comparison.api';
let RecordsList = class RecordsList extends Vue {
    constructor() {
        super(...arguments);
        this.mergedObjects = {};
        this.objectName = this.object.name;
        this.paginationOptions = {
            action: 'init',
            startIndexRecord: 0,
            startIndexFile: 0,
            finishIndexRecord: 0,
            finishIndexFile: 0,
            isLastPage: true,
            isFirstPage: true,
        };
        this.openedRecord = null;
        this.records = [];
        this.loading = false;
    }
    onStartLoading() {
        this.loading = true;
    }
    onEndLoading() {
        this.loading = false;
    }
    openRecord(record) {
        this.openedRecord = this.openedRecord === record ? null : record;
    }
    mounted() {
        this.fetchRecords();
    }
    nextPage() {
        if (this.loading) {
            return;
        }
        this.paginationOptions.action = 'next';
        this.fetchRecords();
    }
    prevPage() {
        if (this.loading) {
            return;
        }
        this.paginationOptions.action = 'prev';
        this.fetchRecords();
    }
    async fetchRecords() {
        this.onStartLoading();
        try {
            const { paginationOption, compareResultRecords } = await ComparisonApi.listObjectRecords({
                objectName: this.object.name,
                backupId: this.comparisonState.backup?.id,
                backupOptionId: +this.object.id,
                projectName: this.comparisonState.projectName,
                orgId: +this.organizationId,
                paginationOption: this.paginationOptions,
                storageId: this.comparisonState.storageId,
            });
            this.paginationOptions = { ...this.paginationOptions, ...paginationOption };
            this.records = compareResultRecords;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.onEndLoading();
        }
    }
};
__decorate([
    Inject()
], RecordsList.prototype, "comparisonState", void 0);
__decorate([
    Inject()
], RecordsList.prototype, "organizationId", void 0);
__decorate([
    Prop()
], RecordsList.prototype, "objectDescribe", void 0);
__decorate([
    Prop()
], RecordsList.prototype, "object", void 0);
__decorate([
    Provide()
], RecordsList.prototype, "objectName", void 0);
__decorate([
    Emit()
], RecordsList.prototype, "onStartLoading", null);
__decorate([
    Emit()
], RecordsList.prototype, "onEndLoading", null);
RecordsList = __decorate([
    Component({
        components: {
            RecordItem,
        },
    })
], RecordsList);
export default RecordsList;
