import Vue from 'vue';
import { StorageType } from '@flosum-backup/contracts';
import BackupStorageSelectModal from '@/components/BackupStorageSelectModal.vue';
export default class BackupUtils {
    static async getStorageForBackup(backup) {
        const { storages } = backup;
        if (!backup.isAccessImmediate) {
            throw new Error('Storage type for this backup do not support instant retrieve. Retrieve backup from cloud storage to continue.');
        }
        // if backup in local storage - return local storage Id
        const localStorage = storages.find(storage => storage.type === StorageType.Local);
        if (localStorage) {
            return {
                id: localStorage.id,
            };
        }
        const immediateStorages = storages.filter(storage => Boolean(storage.isAccessImmediate));
        if (immediateStorages.length === 1) {
            return {
                id: immediateStorages[0].id,
            };
        }
        const pickedStorage = await Vue.modal.show(BackupStorageSelectModal, {
            name: backup.name,
            storages: immediateStorages,
        });
        if (!pickedStorage) {
            return null;
        }
        return {
            id: pickedStorage.id,
        };
    }
}
