import { __decorate } from "tslib";
import { Vue, Component, Inject, Emit } from 'vue-property-decorator';
import Expandable from '@/components/shared/Expandable.vue';
import RecordsList from './RecordsList.vue';
import ComparisonApi from '@/services/api/comparison.api';
import { INIT_MERGED_RECORD, RESET_MERGE_RESULT } from '@/store/comparison-merge/mutations';
import { HAS_SELECTED_RECORDS } from '@/store/comparison-merge/getters';
let MergeObjects = class MergeObjects extends Vue {
    constructor() {
        super(...arguments);
        this.openedObject = -1;
        this.objectsDescribe = null;
        this.loading = false;
    }
    get isNextActive() {
        return this.$store.getters[HAS_SELECTED_RECORDS];
    }
    get objects() {
        return this.comparisonState.objects || [];
    }
    onMerged() { }
    openObject(objectName) {
        if (this.openedObject === objectName) {
            this.openedObject = -1;
        }
        else {
            this.openedObject = objectName;
        }
    }
    mounted() {
        this.$store.commit(RESET_MERGE_RESULT);
        this.$store.commit(INIT_MERGED_RECORD, {
            fields: this.objects.map(object => object.name),
        });
        this.fetchData();
    }
    async fetchData() {
        this.loading = true;
        try {
            this.objectsDescribe = await ComparisonApi.describeObjects(this.organizationId, this.comparisonState.projectName, this.objects.map(object => object.name));
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], MergeObjects.prototype, "organizationId", void 0);
__decorate([
    Inject()
], MergeObjects.prototype, "comparisonState", void 0);
__decorate([
    Emit()
], MergeObjects.prototype, "onMerged", null);
MergeObjects = __decorate([
    Component({
        components: {
            Expandable,
            RecordsList,
        },
    })
], MergeObjects);
export default MergeObjects;
