import { __decorate } from "tslib";
import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import ComparisonApi from '@/services/api/comparison.api';
import { chunkArray } from '@/utils';
let BackupObjects = class BackupObjects extends Vue {
    constructor() {
        super(...arguments);
        this.COLUMNS_COUNT = 3;
        this.loading = false;
        this.typesMap = {};
        this.availableTypesFilter = '';
        this.searchText = '';
    }
    get columnSize() {
        // 12 is the maximum column size in bootstrap
        return Math.floor(12 / this.COLUMNS_COUNT);
    }
    get typesChunks() {
        const searchRegexp = new RegExp(this.searchText);
        const typeNames = Object.keys(this.typesMap).filter(typeName => searchRegexp.test(typeName));
        return chunkArray(typeNames, Math.ceil(typeNames.length / this.COLUMNS_COUNT));
    }
    get selectedTypes() {
        return Object.keys(this.typesMap).filter(key => this.typesMap[key].selected);
    }
    onTypesSelected() {
        return this.selectedTypes.map(key => this.typesMap[key]);
    }
    mounted() {
        this.fetchData();
    }
    async fetchData() {
        this.loading = true;
        if (this.comparisonState.backup) {
            try {
                this.typesMap = (await ComparisonApi.listBackupObjects(this.organizationId, this.comparisonState.backup.id, this.comparisonState.projectName)).reduce((acc, type) => ({
                    ...acc,
                    [type.name]: {
                        ...type,
                        selected: false,
                    },
                }), {});
            }
            catch (error) {
                this.$toast.error(error.message);
            }
            finally {
                this.loading = false;
            }
        }
        else {
            await this.$router.replace({ name: 'org.comparison2.index' });
        }
    }
};
__decorate([
    Inject()
], BackupObjects.prototype, "comparisonState", void 0);
__decorate([
    Inject()
], BackupObjects.prototype, "organizationId", void 0);
__decorate([
    Emit()
], BackupObjects.prototype, "onTypesSelected", null);
BackupObjects = __decorate([
    Component({})
], BackupObjects);
export default BackupObjects;
