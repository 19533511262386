import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import FDivider from '@/components/FDivider.vue';
let Stepper = class Stepper extends Vue {
    get columnsCount() {
        return this.steps.length + this.steps.length - 1;
    }
};
__decorate([
    Prop()
], Stepper.prototype, "steps", void 0);
Stepper = __decorate([
    Component({
        components: { FDivider },
    })
], Stepper);
export default Stepper;
